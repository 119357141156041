export const uploadTitle = {
    titleType: '制度',
    manCodes: '帳號',
    userName: '要保人/被保人',
    effectDate: '生效日',
    _seq: ['titleType', 'manCodes', 'userName', 'effectDate']
}

export const uploadColumns = {
    insDetailLink: {
        label: '明細',
        sortEnabled: false,
        isFilterable: false,
        isUnmovable: true,
        isUnhide: true,
        isExcelHide: true,
        type: 'string',
        position: 'center',
        widthPx: 65,
        toolTips: {
            hasToolTips: true,
            content: {
                desktop: '查看保單明細及編輯部分欄位',
                mobile: null
            }
        }
    },
    payer: {
        label: '要保人',
        sortEnabled: true,
        isFilterable: true,
        type: 'string',
        position: 'center',
        width: 11,
        widthPx: 105
    },
    insured: {
        label: '被保人',
        sortEnabled: true,
        isFilterable: true,
        type: 'string',
        position: 'center',
        width: 11,
        widthPx: 105
    },
    supplier: {
        label: '保險公司',
        sortEnabled: true,
        isFilterable: true,
        type: 'string',
        position: 'center',
        width: 12,
        widthPx: 110
    },
    insNo: {
        label: '保單號碼',
        sortEnabled: true,
        isFilterable: true,
        type: 'string',
        position: 'center',
        width: 17,
        widthPx: 150
    },
    effectiveDate: {
        label: '生效日',
        sortEnabled: true,
        isFilterable: true,
        type: 'date',
        position: 'center',
        width: 8,
        widthPx: 100
    }
}

/**
 * 活動進度查詢table columns setting
 */
export const ctwEventColumns = {
    userName: {
        label: '客戶姓名',
        sortEnabled: true,
        isFilterable: true,
        type: 'string',
        position: 'center',
        width: 10,
        widthPx: 100
    },
    registerTaskStatus: {
        label: '任務一進度',
        sortEnabled: true,
        isFilterable: true,
        type: 'string',
        position: 'center',
        width: 10,
        widthPx: 100
    },
    uploadTaskStatus: {
        label: '任務二進度',
        sortEnabled: true,
        isFilterable: true,
        type: 'string',
        position: 'center',
        width: 10,
        widthPx: 100
    },
    registerDate: {
        label: '綁定日',
        sortEnabled: true,
        isFilterable: true,
        type: 'date',
        position: 'center',
        width: 10,
        widthPx: 100
    },
    userId: {
        label: '身分證號碼',
        sortEnabled: true,
        isFilterable: true,
        type: 'string',
        position: 'center',
        width: 10,
        widthPx: 100
    },
    userBirthday: {
        label: '生日',
        sortEnabled: true,
        isFilterable: true,
        type: 'date',
        position: 'center',
        width: 10,
        widthPx: 100
    },
    mobile: {
        label: '手機/電話',
        sortEnabled: true,
        isFilterable: true,
        type: 'string',
        position: 'center',
        width: 10,
        widthPx: 100
    }
}

/**
 * 活動進度查詢篩選
 */
export const ctwEventFilterTitles = {
    manCodes: '帳號',
    eventId: '活動名稱',
    userName: '客戶名稱',
    registerDate: '綁定日',
    _seq: ['manCodes', 'eventId', 'userName', 'registerDate']
}

/**
 * 客戶查詢table columns setting
 */
export const ctwCustomerColumns = {
    insLink: {
        label: '保單',
        sortEnabled: true,
        isFilterable: true,
        type: 'string',
        position: 'center',
        widthPx: 65,
        isExcelHide: true
    },
    userName: {
        label: '客戶姓名',
        sortEnabled: true,
        isFilterable: true,
        type: 'string',
        position: 'center',
        width: 10,
        widthPx: 100
    },
    registerDate: {
        label: '綁定日',
        sortEnabled: true,
        isFilterable: true,
        type: 'string',
        position: 'center',
        width: 10,
        widthPx: 100
    },
    userId: {
        label: '身分證號碼',
        sortEnabled: true,
        isFilterable: true,
        type: 'date',
        position: 'center',
        width: 10,
        widthPx: 100
    },
    userBirthday: {
        label: '生日',
        sortEnabled: true,
        isFilterable: true,
        type: 'string',
        position: 'center',
        width: 10,
        widthPx: 100
    },
    userAge: {
        label: '年齡',
        sortEnabled: true,
        isFilterable: true,
        type: 'date',
        position: 'center',
        width: 10,
        widthPx: 100
    },
    insAgeIncreaseDate: {
        label: '保險年齡增加日',
        sortEnabled: true,
        isFilterable: true,
        type: 'string',
        position: 'center',
        width: 10,
        widthPx: 100
    },
    mobile: {
        label: '手機/電話',
        sortEnabled: true,
        isFilterable: true,
        type: 'string',
        position: 'center',
        width: 10,
        widthPx: 100
    }
}

/**
 * 客戶查詢篩選
 */
export const ctwCustomerTitles = {
    manCodes: '帳號',
    userName: '客戶姓名',
    registerDate: '綁定日',
    _seq: ['manCodes', 'userName', 'registerDate']
}
