<template>
    <Table
        :rows="tableShowData"
        :isLoading="isLoading"
        :columnConfig="tableColumnConfig"
        :toolbox="['columnSetting', 'excel']"
        :isFixedHeader="true"
        :isFocusRow="true"
        :isPagination="true"
        :sortDefault="{ sort: 'desc', sortBy: 'effectiveDate' }"
        :excelConfig="excelConfig"
    >
        <template v-slot:insDetailLink="{ isHeader, row }">
            <router-link
                v-if="!isHeader"
                :to="{
                    name: 'TiaInsuranceDetail',
                    params: {
                        manCode: row.mainCode,
                        id: this.filterLog.manCodes?.value[0],
                        source: 2
                    }
                }"
                :class="{ 'detail-link-icon': !$isMobile() }"
            >
                <Button
                    v-if="$isMobile()"
                    buttonSize="small"
                    iconClass="detail-link-icon"
                    buttonWord="明細"
                    class="mobile-button"
                />
            </router-link>
        </template>
        <template v-slot:payer="{ isHeader, row }">
            <template v-if="!isHeader">
                <a
                    class="customer"
                    :class="{ 'no-data': row.payer === '-' }"
                    @click.stop="openCustomer(row.payer)"
                >
                    {{ row.payer ?? '-' }}
                </a>
            </template>
        </template>
        <template v-slot:insured="{ isHeader, row }">
            <template v-if="!isHeader">
                <a
                    class="customer"
                    :class="{ 'no-data': row.insured === '-' }"
                    @click.stop="openCustomer(row.insured)"
                >
                    {{ row.insured ?? '-' }}
                </a>
            </template>
        </template></Table
    >
</template>
<script>
import _ from 'lodash'
import Table from '@/components/Table.vue'
import { uploadColumns } from '@/assets/javascripts/ctw/setting'
import Button from '@/components/Button.vue'

export default {
    name: 'CtwUploadTable',
    props: {
        listData: {
            type: Array,
            default: function () {
                return []
            }
        },
        isLoading: {
            type: Boolean,
            default: false
        },
        filterLog: {
            type: Object,
            default: function () {
                return {}
            }
        }
    },
    components: {
        Table,
        Button
    },
    computed: {
        tableShowData() {
            let dataset = _.cloneDeep(this.listData)
            dataset = this.transformData(dataset)
            return dataset
        },
        excelConfig: function () {
            const name = this.filterLog.manCodes?.label
            const filters = _.omit(this.filterLog, ['manCodes', 'titleType'])

            return {
                fileName: `磊山e管家後台_客戶上傳保單_${name}`,
                filters
            }
        }
    },
    methods: {
        openCustomer: function (userName) {
            if (userName === '-') return
            this.$setGaEvent('goSearchCustomer', 'click-Link')
            this.$crossPageFilter('CtwCustomer', { userName })
        },
        transformData(rows) {
            return rows.map((row) => {
                row.payer = row.payer || '-'
                row.insured = row.insured || '-'
                return row
            })
        }
    },
    data() {
        return {
            tableColumnConfig: uploadColumns
        }
    }
}
</script>

<style lang="scss" scoped>
:deep(.ins-detail-link) {
    position: relative;
    > .detail-link-icon {
        width: 24px;
        height: 24px;
        cursor: pointer;
    }
}

.customer {
    display: flex;
    text-decoration: underline;
    color: $primary-blue;
    cursor: pointer;
    &.no-data {
        color: $primary-black;
        cursor: default;
        text-decoration: none;
    }
}

.table-wrapper {
    :deep(.header) {
        .info-icon {
            margin-left: 3px;
            width: 14px;
            height: 14px;
        }
    }
    @media screen and (max-width: 576px) {
        :deep(.card .row-container .row.body) {
            margin-right: auto;

            .ins-detail-link {
                &.cell {
                    max-width: fit-content;
                    justify-content: flex-end;
                    &::before {
                        display: none;
                    }
                }
            }

            .payer {
                &.cell {
                    padding-top: 10px;
                    border-top: 1px dashed $fourth-grey;
                }
            }
        }
        .mobile-button {
            margin-left: 5px;
            background-color: transparent;
            box-shadow: 0 0 0 1px $primary-black inset;
        }
        :deep(.small-button) {
            .button-icon {
                height: 20px;
                width: 20px;
                margin-right: 3px;
            }
        }
    }
}
</style>
